@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.dashboard {
  width: 100%;
  min-height: 53vh;
  transition: 0.1s all ease-out;

  .dashboard-charts-container {
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    .bar-chart {
      width: 68%;
      min-height: 100px;
      background: #ffffff;

      border-radius: 10px;
      // padding-top: 10px;
      .bar {
        @media only screen and (max-width: 800px) {
          padding-left: 10px;
          padding-right: 10px;
        }

        .bar-filter-date {
          margin: 20px 0px;
          display: flex;
          justify-content: flex-start;
        }

        .range-date-chosen {
          display: flex;
          justify-content: center;
          align-items: center;
          @include font-size14();
          color: $primary-color;
        }
      }
    }

    .doughnut-chart {
      width: 28.1%;

      min-height: 30.1%;

      border-radius: 10px;
      .MuiGrid-root {
        @media only screen and (max-width: 900px) {
          margin-top: 10%;
        }
        canvas {
          margin-top: -40px !important;
        }
      }
    }
    .chart-title-bar {
      width: 100%;
      height: 10px;
      display: flex;

      @media only screen and (max-width: 1200px) {
        padding-left: 10px;
        h6 {
          font-size: 1rem;
        }
      }
    }

    .chart-title-doughnut {
      width: 100%;
      height: 55px;
      display: flex;

      // margin-bottom: -60px;
      @media only screen and (max-width: 1200px) {
        padding-left: 10px;
        h6 {
          font-size: 1rem;
        }
      }
      h3 {
        margin-left: 3%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .dashboard {
    padding: 10px;
    .dashboard-charts-container {
      flex-direction: column;

      .doughnut-chart {
        width: 100% !important;
        min-height: 100px;

        margin: auto;
      }

      .bar-chart {
        width: 100%;
        margin-bottom: 40px;

        .bar {
          padding: 0px 15px 10px 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .dashboard {
    .dashboard-charts-container {
      flex-direction: column;

      .doughnut-chart {
        width: 50%;
        min-height: 100px;
        margin: auto;
      }

      .bar-chart {
        width: 100%;
        margin-bottom: 40px;

        .bar {
          padding: 0px 15px 10px 15px;
        }
      }
    }
  }
}

.transaction-dashboard {
  margin: 10px 0px;
  .dashboard-sub-title {
    @include font-size30();
    font-weight: 500;
  }
}
