@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.sellmydevice {
  .MuiTab-root {
    text-transform: none;

    &.Mui-selected {
      color: #3538cd;
    }
  }
  .MuiTabs-indicator {
    background-color: #3538cd;
    /* Hide the default indicator */
  }
}

.sectionone {
  padding: 10px 5px;

  .flexcontainer {
    display: flex;
    flex-direction: column;

    // For larger screens
    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .form-section,
    .image-section {
      flex: 1;
    }

    .image-section {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .colorPicker {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .action-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 30px !important; // Add margin-top here

        button {
          padding: 10px 20px;
          height: 48px;
          border-radius: 4px;
          margin-top: 80px !important;
          cursor: pointer;
          font-family: inherit;

          &.cancel-button {
            background-color: #ff5a5f;
            color: white;
          }

          &.save-button {
            background-color: #007bff;
            color: white;
          }
        }
      }
    }
  }
}

.sectiontwo {
  padding: 10px 5px;

  .switch {
    justify-content: end;
    display: flex;
    align-items: center;
  }

  img {
    height: unset !important;
  }

  .flexcontainer {
    display: flex;
    flex-direction: column;

    // For larger screens
    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .form-section,
    .image-section {
      flex: 1;
    }
  }

  .image-section {
    display: flex;
    flex-direction: column;

    .colorPicker {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .action-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 30px !important; // Add margin-top here
    }
  }

  .halfHeight {
    height: 50%;
  }
}

// Responsive adjustments for smaller screens
@media screen and (max-width: 768px) {
  .sectionone .flexcontainer {
    flex-direction: column;
  }

  .sectionone .image-section {
    .action-footer {
      flex-direction: column;
      align-items: flex-end;

      button {
        width: 100%;
      }
    }
  }
}
