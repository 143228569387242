.productskuList {
  .paper-div {
    border-radius: 10px;
    min-height: 300px;
    padding: 20px;
  }

  .flexbutton {
    display: flex;
    flex-direction: row;
    gap: 2;
    justify-content: end;
    align-items: end;
  }
}
