@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";
.parentIdChange {
  &.disabled {
    opacity: 0.8;
    filter: grayscale(80%);
    pointer-events: none;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .MuiCircularProgress-root {
      margin-left: auto;
    }
  }
  .action-footer {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
  }
}
.categoryList {
  padding: 10px;
  margin: 20px 0;
  display: flex;
  overflow-y: scroll;
  height: 30vh;
  flex-direction: column;
  gap: 15px;

  .category-list-item:hover {
    background-color: $primary-color;
    color: white;
  }

  .category-product-count {
    color: $primary-color;
    @include font-size14();
    margin: 0px 4px;
  }
  button {
    justify-content: flex-start;
    box-shadow: $box-shadow;
    padding: 15px 20px;
    border-radius: 10px;
  }
}

.CategoryDetails {
  .category-detail-component {
    width: 100%;
    display: flex;
    gap: 1%;
    @media only screen and (max-width: $sm-screen) {
      flex-direction: column;
    }
  }

  .form-section {
    width: 50%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $sm-screen) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .upload-section {
    width: 50%;
    @media only screen and (max-width: $sm-screen) {
      width: 100%;
    }
  }

  .switches-status-group {
    margin: 10px 0px;
  }
  .switchGroup {
    display: flex;
    flex-flow: row nowrap;
    gap: 10%;
    align-items: center;
  }
  .switches-type-group {
    margin: 10px 0px;
  }

  .action-footer {
    display: flex;
    justify-content: flex-end;
  }
}
