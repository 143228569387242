// Color Picker Styling
.color-picker-container {
    display: flex;
    flex-direction: column;
  
    .color-picker-label {
      font-weight: bold;
      margin-bottom: 8px;
    }
  
    .color-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
  
      .color-box {
        width: 32px;
        height: 32px;
        border: 2px solid transparent;
        border-radius: 4px;
        cursor: pointer;
        transition: border-color 0.2s;
  
        &.selected {
          border-color: #000;
        }
      }
  
      .color-input {
        width: 100px;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        text-align: center;
      }
    }
  }