@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.bannerPage {
  .bannerDetails {
    width: 100%;
    display: flex;
    gap: 10px;
    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
    }
  }
  .bannerDetailsCard {
    flex-basis: 56%;
  }
  .upload-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    flex-basis: 40%;
    @media screen and (max-width: 600px) {
      flex-direction: row;
    }
  }
  .url-link {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px 20px;
    align-items: flex-end;
    justify-items: end;
    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      justify-items: start;
      margin-bottom: 10px;
    }
  }
  .form-double-children {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 40px;
    align-items: center;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
  .action-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
