$primary-color: #4ba4f0;
$primary-color-opacity: rgba(75, 164, 240, 0.3);
$dark-primary: #041c3f;
$blue: $primary-color;
$light-primary-color: #e9f5ff;
$light-gray: #a8a8a8;
$black: #041c3f;
$white: #ffffff;
$darkwhite: #e5e5e5;
$grey: #9a9a9a;
$light-grey: #c1c1c1;
$border-grey: #c4c4c4;
$darkest-grey: #646464;
$dark-grey: #8c8c8c;

:export {
  primaryColor: $primary-color;
  primaryColorOpacity: $primary-color-opacity;
  darkPrimary: $dark-primary;
  blue: $blue;
  whiteColor: $white;
  darkWhite: $darkwhite;
  grey: $grey;
  borderGrey: $border-grey;
  lightPrimaryColor: $light-primary-color;
}
