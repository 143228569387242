.categoryItem {
  border-radius: 10px;
  padding: 20px 18px 20px 40px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #041c3f;
    margin: 0;
  }
  div {
    margin: 0;
  }
  div {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
  }
  .ic {
    width: auto;
    display: flex;
    flex-direction: row;
    column-gap: 0.7rem;
    span {
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }
}
.categoryHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 18px;
  margin: 10px 0px;
  div {
    color: #4ba4f0;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
  }
  div p {
    cursor: pointer;
  }
}
