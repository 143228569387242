.Menueditor {
    .menu-editor {
        margin-top: 10px;
    }

    .header-detail {
        margin-bottom: 10px;
    }

    .icon-margin {
        margin-right: 10px;
    }

    .action-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px !important;
        flex-wrap: wrap;

        button {
            margin-right: 10px;
        }

        button:last-child {
            margin-right: 0;
        }

        @media (max-width: 600px) {
            flex-direction: column;
            align-items: center;

            button {
                width: 100%;
                margin-bottom: 10px;
                text-align: center;
            }

            button:last-child {
                margin-bottom: 0;
            }
        }
    }
}