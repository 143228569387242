@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";
@import "/src/theme/form.scss";

#root {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  padding-top: 80px;

  a {
    text-decoration: none;

    &:hover {
      color: unset;
    }
  }

  width: 100vw;
  overflow-x: hidden;
}

//all links black colored
a {
  color: black;
}

.fade-in {
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

form {
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.CircularProgress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  margin: 0;
}

.currency {
  &::before {
    content: "AED ";
  }
}

.w-500 {
  min-width: 500px;
}

.loaderClass {
  position: "absolute";
  left: "50%"; // Center horizontally
  top: "50%"; // Center vertically
  transform: "translate(-50%, -50%)";
}

.direction-left {
  direction: ltr !important;
}

.direction-right {
  direction: rtl !important;
}