@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";
.change_password {
  width: 100%;

  margin: 20px 0px;
  padding: 15px 20px;

  .action-footer {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
  }
}
