.paper-div-store {
    padding: 20px;
    margin: 15px 0px;
}

.icon-margin {
    margin-right: 8px;
}

.header-detail {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.en-chip {
    margin: 0px 5px !important;

}

.color-white {
    color: #fff !important;

}

.store-title {
    padding-bottom: 15px;
    font-size: 22px;
    font-weight: 500;
}

.displayEnd {
    display: flex;
    justify-content: end;
    @media screen and (max-width: 768px) {
        justify-content: start;
    }
}