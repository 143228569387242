@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.dialogComp {
  overflow: hidden !important;
  .modalBody {
    display: flex;
    flex-direction: column;
    max-width: inherit;
    padding: 10px 16px 8px !important;
    text-align: center;
    min-width: 300px;

    justify-content: center;
  }
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background-color: #041C3F;
    color: #fff;
    .closeSVG {
      align-self: flex-end;
      color: #fff;
    }
  }
  .Buttons {
    display: flex;
    width: 100%;
    gap: 3%;
    justify-content: end;
    margin-top: auto;
    button {
      
    }
  }
  label {
    display: flex;
    color: #041C3F;
  }
  .textField {
    div {
      gap: 15px;
    }
  }
  .orderMethodBody {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    gap: 5%;
    margin-bottom: 50px;
    @media only screen and (max-width: 900px) {
      flex-direction: column;
    }
    .formBody {
      width: 50%;
      margin: 0;
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
    }
    .maps {
      width: 50%;
      @media only screen and (max-width: 900px) {
        flex-direction: column;
        width: 100%;
        height: 300px;
      }
    }
  }
  .modalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 24px;
    .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
      padding: 0;
    }
    span {
      cursor: pointer;
    }
  }
  .css-1u7p39t-MuiPaper-root-MuiDialog-paper {
    border-radius: 8px;
  }
}
