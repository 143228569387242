@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.bar-dashboard-skeleton {
  //   transform: rotate(90deg);
  margin: 50px 0px;
  //   display: flex;
  //   flex-flow: row nowrap;
  //   gap: 10%;
  height: 100%;
}
