@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.spinner {
  // width: 100%;
  // height: 50vh;
  // max-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 200px;
}
