.noDataDiv {
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  margin: auto;
  flex-direction: column;
  margin-top: 150px;
}
.noDataRow {
  // position: relative;
}
.noDataDiv p,
.noData p {
  color: #041c3f;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
}
