@import "/src/theme/color.scss";
.breadcrumbs {
  .MuiBreadcrumbs-ol {
    text-transform: capitalize;
    align-items: flex-end;
  }
  button {
    text-transform: capitalize;
  }
  .MuiBreadcrumbs-separator,
  .last {
    color: $primary-color;
  }
}
