@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.ribbonDetails {
  .description-status {
    display: flex;
    gap: 10px;
  }
  .message {
    color: red;
  }
  .button-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
