@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.disclaimerText {
  page-break-after: always;
  padding-left: 5%;
}
.disclaimer {
  margin: 0% auto;
}
.logoBack {
  display: none;
  @media print {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0%;
    right: 0;
    margin: auto 0;
    height: fit-content;
  }
}
.LogoSVG {
  @media screen {
    display: none;
  }
  position: absolute;
  top: 0;
  right: 0;
}
li::marker {
  color: $primary-color;
}
.disclaimer {
  display: block;

  @media screen {
    max-height: 0;
    overflow: hidden;
  }
}
.printHide {
  @media print {
    display: none !important;
  }
}
