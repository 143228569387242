@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.item-status-handle {
  .action-footer {
    display: flex;
    margin: 10px 0px;
    justify-content: flex-end;
  }
}



  .formfees {
    width: 100%;
  }

  .feesRow {
    display: flex;
    flex-direction: column;
    width: 100%;
    column-gap: 5px;
    justify-content: center;
    align-items: center;
  }

  .dynamic-fees-add {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
  }

  .buttonFees {
    display: flex;
    flex-direction: row;
  }

  .buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 5px
  }
  .feesIconButton{
    &:hover{
      background-color: none !important;
    }
  }
