@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.transaction-detail-component {
  position: relative;

  .header {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0px 0 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    .downloadSVG {
      height: 47px;
      width: 47px;
    }
  }

  .body {
    display: flex;
    flex-flow: column nowrap;

    .sub-title {
      color: $primary-color;
      @include font-size20();
      margin: 10px 0px;
    }

    .edit-product-inspection {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .transaction-description {
      display: flex;
      width: 100%;
      flex-flow: row nowrap;

      gap: 1%;

      @media screen and (max-width: $sm-screen) {
        flex-flow: column nowrap;
      }

      .transaction-detail-item {
        width: 50%;
        @media screen and (max-width: $sm-screen) {
          width: 100%;
        }

        .details-item {
          display: flex;
          flex-flow: row nowrap;
          position: relative;
          overflow-wrap: break-word;
          margin-bottom: 5px;

          @media screen and (max-width: 500px) {
            flex-flow: column nowrap !important;
          }

          .title {
            width: 50%;
            color: #041c3f;
            overflow-wrap: break-word;
            font-weight: 500;
            @include font-size18();
          }
          .body {
            width: 50%;
            color: #041c3f;
            text-align: right;
            overflow-wrap: break-word;
            @media screen and (max-width: 500px) {
              width: 100% !important;
              text-align: left !important;
            }
          }
        }
      }
    }
    .inspections-summary {
      display: flex;
      flex-flow: column;
      width: 100%;

      .insp-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
      }
      .inspections-actions {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        margin: 20px 0px;
        width: 100%;
        gap: 2%;
      }
    }

    .inspections-list {
      .inspection-accordion {
        margin-bottom: 5px;

        .inspection-header {
          display: flex;
          flex-flow: column;

          div {
            color: $grey;
            font-weight: 500;
            @include font-size14();
          }
        }
      }
      .inspection-question-element {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        .inspection-element {
          width: 50%;

          &.only-answer {
            text-align: right;
          }
        }
      }
    }
  }

  .shipping-body-item {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    overflow-wrap: break-word;
    margin-bottom: 20px;

    @media screen and (max-width: 500px) {
      flex-flow: column nowrap !important;
      margin-bottom: 20px;
    }

    div:nth-child(1) {
      width: 50%;
      color: #041c3f;
      overflow-wrap: break-word;
      font-weight: 500;
      @include font-size18();
    }
    div:nth-child(2) {
      width: 50%;
      color: #041c3f;
      text-align: right;
      overflow-wrap: break-word;
      @media screen and (max-width: 500px) {
        width: 100% !important;
        text-align: left !important;
      }
    }
  }
}

.product-questions {
  .question-all-list {
    max-height: 400px;
    overflow-y: scroll;
  }
  .inspection-question-element {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    .inspection-element {
      width: 50%;

      &.only-answer {
        text-align: right;
      }
    }
  }
  .action-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 10px;
  }
}
