.partner-section {
    .flexcontainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        align-items: stretch;
        background-color: #fff;
        width: 100%;
        padding: 20px;

        .form-section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .form-input {
                display: flex;
                flex-direction: column;
                gap: 5px;

                label {
                    font-weight: bold;
                    margin-bottom: 5px;
                }

                input[type="text"],
                textarea {
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    background-color: #f5f5f5;
                    font-size: 14px;
                }

                textarea {
                    resize: none;
                    height: 80px;
                }
            }

            .switch {
                display: flex;
                align-items: center;
                gap: 10px;

                input[type="checkbox"] {
                    margin-right: 10px;
                }

                label {
                    font-size: 14px;
                }
            }
        }

        .image-section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .imgwidth200 {
                max-width: 100%;
                border: 2px dashed #ccc;
                border-radius: 4px;
                padding: 20px;
                text-align: center;
                background-color: #f9f9f9;
                cursor: pointer;

                &:hover {
                    border-color: #888;
                }

                p {
                    margin: 0;
                    font-size: 16px;
                    color: #555;
                }

                small {
                    color: #888;
                }
            }

            .action-buttons {
                display: flex;
                justify-content: end;
                gap: 10px;
                margin-top: 20px;

                .cancel-button {
                    background-color: #ff5a5f;
                    color: white;
                    padding: 10px 20px;
                    width: 120px;
                    height: 48px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                }

                .add-button {
                    background-color: #007bff;
                    color: white;
                    padding: 10px 20px;
                    border: none;
                    width: 171px;
                    height: 48px;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }
        }

        @media screen and (min-width: 768px) {
            flex-direction: row;

            .form-section,
            .image-section {
                flex: 1;
            }

            .action-buttons {
                margin-top: 0;
            }
        }
    }

}

.width100 {
    width: 100% !important;
}

.width10 {
    width: 10% !important;
}

.width50 {
    width: 50% !important;
}


.error {
    color: #ff0000;
    font-size: 14px;
    font-weight: 400;
}