@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.open-transaction-component {
  .paper-div {
    border-radius: 10px;
    min-height: 300px;
    padding: 5px 24px 24px 24px;
  }

  .condition-button-container {
    display: flex;
    justify-content: end;
    margin-bottom: 5px;
  }

  .chipGreen {
    color: #66BD50;
    background: #E0FFD9;
  }

  .chipRed {
    color: #de4841;
    background: #ffdad9;
  }

  .alignItemCenter {
    align-items: center !important;
    justify-content: center;
    display: flex !important;
    text-align: center !important;
  }

}