@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.productsku {
  .formInputWrapper {
    margin: 0 px 0 px 15 px 0 px;
  }
  .addCard {
    width: 50%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

    @media only screen and (max-width: $sm-screen) {
      width: 100%;
    }
  }
}
.skuDetails {
  display: flex;
  column-gap: 1rem;

  @media screen and (max-width: $sm-screen) {
    flex-flow: column;
  }
  .cardSku {
    width: 50%;
    height: fit-content;

    border-radius: 10px;

    .action-footer {
      display: flex;
      justify-content: flex-end;
    }
    @media screen and (max-width: $sm-screen) {
      width: 100%;

      margin-bottom: 10px;
    }
  }
  .cardSkuItems {
    width: 50%;
    border-radius: 10px;

    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }

    .status-link {
      color: black;
      text-decoration: underline !important;
    }

    .status-link:hover {
      color: $primary-color !important;
    }
  }
}
.item-status-handle {
  .action-footer {
    display: flex;
    margin: 10px 0px;
    justify-content: flex-end;
  }
}
