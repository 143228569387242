.main-body {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  min-height: calc(100vh - 80px);
  max-width: 2000px;
  position: relative;
  .page-header {
    text-transform: capitalize;
  }
  &.sideopen {
    padding-left: 230px;
    @media only screen and (max-width: 800px) {
      padding: 0;
    }
  }
}

#root {
  background-color: #f8faff;
  overflow-y: overlay;
  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.37);
    border-radius: 50px;
    &:hover {
      background: rgba(63, 63, 63, 0.761);
      transform: scale(1.2);
    }
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
}
