.table-Buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #8cb8f4 !important;
  overflow: scroll;

  .error-message {
    color: #ff0000;
  }

  .responsive-header {
    font-size: 14px;
  }

  @media (max-width: 1200px) {
    .responsive-header {
      font-size: 12px;
    }
  }

  @media (max-width: 600px) {
    .responsive-header {
      font-size: 10px;
    }
  }

  .iconTableButton {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .inputStyle {
    border: none;
    outline: none;
    padding: 8px;
    font-size: 16px;
    width: 100%;
  }

  .inputStyle:focus {
    background-color: transparent;
  }

  /* Hide spinners on number input */
  .inputStyle::-webkit-inner-spin-button,
  .inputStyle::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
