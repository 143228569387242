@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.contactUs {
  .MuiTab-root {
    text-transform: none;

    &.Mui-selected {
      color: #3538cd;
    }
  }

  .MuiTabs-indicator {
    background-color: #3538cd;
    /* Hide the default indicator */
  }
}

.findusSection {
  background-color: #fff;
  padding: 10px 5px;
}

.contactusSection {
  background-color: #fff;
  padding: 10px 5px;

  .codeCountry {
    width: 25%;
    min-width: 100px;
  }
}
