.productsList {
    .paper-div {
        border-radius: 10px;
        min-height: 300px;
        padding: 24px;
    }

    .condition-button-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
            justify-content: start;
            gap: 10px;
            margin: 10px 0px;
        }

        .allbuttons {
            display: flex;
            flex-direction: row;

            @media only screen and (max-width: 600px) {
                flex-direction: column;
                justify-content: start;
                gap: 10px;
            }
        }

    }



    .width-25 {
        width: 25%;
    }

    .width-50 {
        width: 50%;
    }

    .width-75 {
        width: 75%;
    }

    .width-100 {
        width: 100%;
    }

    .mt-17 {
        margin-top: 2px !important;
    }

    .mt-6 {
        margin-top: 6px !important;
    }

    .w-90 {
        width: 90%;
    }

}


.button-group {
    display: flex;
    justify-content: end;
    margin-top: 5px;
}