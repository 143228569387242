@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";

.BrandDetails {
  width: 100%;
  display: flex;
  gap: 1%;
  @media only screen and (max-width: $sm-screen) {
    flex-direction: column-reverse;
    gap: 10px;
  }

  .form-section {
    width: 50%;
    height: fit-content;
    @media only screen and (max-width: $sm-screen) {
      width: 100%;
      margin-bottom: 10px;
    }

    .action-footer {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0px;
    }
  }

  .upload-section {
    width: 50%;
    @media only screen and (max-width: $sm-screen) {
      width: 100%;
    }
  }
  .BrandDetailsCard {
    width: 50%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $sm-screen) {
      width: 100%;
      margin-bottom: 10px;
    }

    .buttonComponent {
      margin-left: auto;
      margin-top: 15px;
    }
  }
}
