@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.exportList {
  .paper-div {
    border-radius: 10px;
    min-height: 300px;
    padding: 20px;
  }

  .reportType {
    background-color: #c7d7fe;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: left;
    width: 150px;
    justify-content: center;
    margin-bottom: 0px;
    background-color: #c7d7fe;
    color: #3538cd;
  }

  .organizationReport {
    background-color: #d4edda;
    color: #00b69b !important;
    /* Green background for Organization Report */
  }

  .branchReport {
    background-color: #c7d7fe;
    color: #3538cd;

    /* Purple background for Branch Report */
  }

  .userReport {
    background-color: #cce5ff;

    /* Blue background for User Report */
  }

  .transactionReport {
    background-color: #ffa756;
    /* Default color for other types */
  }

  .itemReport {
    background-color: #ba29ff;
    opacity: 10%;
    color: #ba29ff;
    /* Default color for other types */
  }
}
