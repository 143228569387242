.attributeDetails {
  .dynamic-attribute-add {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
  }

  .add {
    margin-top: 20px;
    background: #edf6fe;
    border-radius: 10px;
    padding: 20px;
    color: #4ba4f0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;

    p {
      margin: 0;
      align-self: center;
    }
  }

  .action-footer {
    display: flex;
    justify-content: flex-end;
  }

  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h1 {
      color: #041c3f;
    }

    .drag {
      color: #4ba4f0;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0;
      cursor: pointer;
      column-gap: 1rem;
    }
  }

  h1 {
    margin-bottom: 20px;
  }

  .uploadTextWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .w-50 {
    width: 50% !important;
  }
}