@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";
.personal_info {
  position: relative;
  box-shadow: $box-shadow;
  margin-top: 20px;
  padding: 15px 20px;
  @media only screen and (max-width: 601px) {
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  .codeCountry {
    width: 25%;
    min-width: 100px;
  }
  .rows {
    display: flex;
    gap: 5%;
    @media only screen and (max-width: 601px) {
      flex-direction: column;
    }
    .inputControl {
      width: 100%;
    }
  }
  .phoneInput {
    width: 47.5%;
    @media only screen and (max-width: 601px) {
      width: 100%;
    }
    .search-select {
      width: 25%;
    }
  }
  .enable-notification {
    @include font-size();
    color: $black;
    margin-top: 45px;
    padding-bottom: 36px;
  }

  .MuiFormControl-root {
    @include font-size22();
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    .buttonPersonal {
      position: absolute;
      bottom: 5%;
      right: 0;
    }
  }
  .code {
    width: 40%;
  }
}
