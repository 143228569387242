@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.mainWrapperEditor {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  .makeStyles-editorWrapper-1 {
    margin: 0;
    padding: 1rem;
    border-radius: 0px 0px 10px 10px;
    box-shadow: none;
  }
  .MuiPaper-elevation1 {
    background: #eaf1fc;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #4ba4f0;
    box-shadow: none;
  }
  .MuiPaper-elevation3 {
    box-shadow: none;
  }
  .MuiIconButton-root {
    color: #4ba4f0;
  }
  .MuiInputBase-root {
    color: #4ba4f0;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #4ba4f0;
  }
  .MuiSelect-icon {
    color: #4ba4f0;
  }
  .MuiIconButton-root {
    padding: 15px;
  }
}

//NEW EDITOR CSS
.form-input-wrapper {
  margin: 0px 0px 15px 0px;
}
.toolbarTextEditor {
  border-radius: 10px 10px !important;
}
.wrapperTextEditor {
  box-shadow: $box-shadow;
  padding: 5px 0px;

  border-radius: 10px 10px;
}
.rdw-option-wrapper {
  border: unset;
  box-shadow: unset;
  font-weight: bold;
  color: $primary-color !important;
}

.rdw-dropdown-wrapper {
  background-color: #eaf1fc !important;
  border: unset;
  box-shadow: unset;
}
.rdw-option-active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.editor-buttonIcon-className {
  img {
    transform: scale(0.6);
  }
}
