@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.transaction-detail-component-buy {
  position: relative;

  .header {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0px 0 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;

    .downloadSVG {
      height: 40px;
    }

    .transactionStatus {
      padding: 4px 0 5px;
      border: 1px solid $primary-color;
      padding: 8.5px 14px;
      border-radius: 5px;
    }
  }

  .body {
    display: flex;
    flex-flow: column nowrap;

    .sub-title {
      color: $primary-color;
      @include font-size20();
      margin: 10px 0px;
    }

    .transaction-description {
      display: flex;
      width: 100%;
      flex-flow: row nowrap;

      gap: 1%;

      @media screen and (max-width: $sm-screen) {
        flex-flow: column nowrap;
      }

      .transaction-detail-item {
        width: 50%;

        @media screen and (max-width: $sm-screen) {
          width: 100%;
        }

        .details-item {
          display: flex;
          flex-flow: row nowrap;
          position: relative;
          overflow-wrap: break-word;
          margin-bottom: 5px;

          @media screen and (max-width: 500px) {
            flex-flow: column nowrap !important;
          }

          .title {
            width: 50%;
            color: #041c3f;
            overflow-wrap: break-word;
            font-weight: 500;
            @include font-size18();
          }

          .body {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            flex-wrap: nowrap;
            align-items: center;
            gap: 10px;
            width: 50%;
            color: #041c3f;
            overflow-wrap: break-word;

            @media screen and (max-width: 500px) {
              width: 100% !important;
              text-align: left !important;
            }
          }
        }
      }

      .transaction-total-card {
        margin: 20px 0px;

        .transaction-total-detail {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title {
            color: $primary-color;
            @include font-size18();
            font-weight: 500;
          }
        }
      }
    }

    .shipping-body-item {
      display: flex;
      flex-flow: row nowrap;
      position: relative;
      overflow-wrap: break-word;
      margin-bottom: 20px;

      @media screen and (max-width: 500px) {
        flex-flow: column nowrap !important;
        margin-bottom: 20px;
      }

      div:nth-child(1) {
        width: 50%;
        color: #041c3f;
        overflow-wrap: break-word;
        font-weight: 500;
        @include font-size18();
      }

      div:nth-child(2) {
        width: 50%;
        color: #041c3f;
        text-align: right;
        overflow-wrap: break-word;

        @media screen and (max-width: 500px) {
          width: 100% !important;
          text-align: left !important;
        }
      }
    }
  }

  .product-sku-items-component {
    .product-sku-items-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }

  .inspections-actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0px;
    width: 100%;
    gap: 2%;
  }

  .selected-quantity {
    color: $primary-color;
    font-weight: bold;
  }
}

.logistic {
  background-color: white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: 16px 24px;
  display: flex;
  gap: 10px;
  width: 35%;
  min-width: 350px;

  img {
    object-fit: contain;
    min-width: 50px;
  }

  width: fit-content;
  height: fit-content;

  a {
    color: #0d6efd;
    cursor: pointer;
  }
}

.bottomSection {
  display: flex;
  gap: 5%;

  .trackYourOrder {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    flex-basis: unset;
    padding: 16px 24px;

    .MuiStepper-root {
      padding: 0 0 10px;
    }
  }
}

.transaction-detail-buy {
  .paper-div {
    border-radius: 10px;
    padding: 10px;
  }

  .header {
    display: flex;
    align-items: center;
    color: rgb(18, 25, 38);
    padding: 10px;
    border-bottom: 1px solid $border-grey;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: self-start;
      gap: 20px;
    }

    .title-details {
      font-size: 1.125rem !important;
      margin: 0px;
      font-size: 0.875rem;
      color: $primary-color;
      font-weight: 500;
      line-height: 1.334;
      display: block;
    }

    .transactionStatus {
      padding: 4px 0 5px;
      border: 1px solid $primary-color;
      padding: 8.5px 14px;
      border-radius: 5px;
    }

    .chip-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: end;
      width: 20%;

      @media only screen and (max-width: 800px) {
        justify-content: start;
        width: 100%;
      }

      .en-chip {
        margin: 0px 5px;
      }

      .color-white {
        color: #fff;
      }
    }
  }

  .shipping-details {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;
    gap: 5px;
  }
}

.formmodalinputs {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-evenly;
}

.cursorpointer {
  cursor: pointer;

}

.titlecursor{
  &:hover {
    color: #4ba4f0 !important;
  }
}