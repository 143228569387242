@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.LoginPage {
  width: 846px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 460px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(
    -45deg,
    #edf9ff,
    $primary-color-opacity,
    #ffffff,
    #71e4ac88
  );
  background-size: 400% 400%;
  animation: 10s ease-in-out 0s infinite normal both running login;
  @keyframes login {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 800px;
  }

  @media screen and (max-width: 500px) {
    width: fit-content;
  }
  .wrap1 {
    display: flex;
    flex-direction: column;
    width: 70%;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
    .mobilelogo {
      display: flex;
      justify-content: center;
      padding: 20px 0px;
      @media screen and (min-width: 700px) {
        display: none;
      }
    }
    .loginForm {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .forgotPassword {
        width: fit-content;
        align-self: flex-end;
        color: rgba(154, 154, 154, 1);
        text-decoration: none;
        margin-bottom: 2%;
        float: right;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  .wrap2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    padding: 3px 0px 3px 40px;

    .logo {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
}
/* css file */
.disabled-link {
  pointer-events: none;
}
.curvedInput {
  .MuiInputAdornment-root {
    border-radius: 50px;
  }

  .MuiInputBase-input:focus {
    border-width: 1px;
    background-color: transparent !important;
  }
  .MuiInput-root {
    &::after {
      border-width: 1px !important;
    }
    &::before {
      border-width: 1px !important;
    }
  }
  input {
    padding: 10px 14px;
  }
  .MuiOutlinedInput-root {
    border-radius: 50px;
  }
}
