@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.non-form-wrapper {
  width: 100%;
}

.form-input-wrapper {
  margin: 0px 0px 15px 0px;

  .form-switch-label {
    margin-left: 0px !important;
  }
}

.required-start {
  color: "red";
}

.form-single-upload {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 250px;
  box-shadow: 0px 0px 4px rgb(222 228 237 / 80%);
  border-radius: 4px;

  .form-upload-single-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .required-start-images {
    color: #4ba4f0;
    justify-content: flex-end;
    display: flex;
    height: 12px;
  }
}

.form-upload-children {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .ratio-label {
    color: $grey;
  }
}

.dropzone {
  background-color: white;

  .imagesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    row-gap: 20px;

    &.images {
      img {
        object-fit: contain;
        width: 80px;
        height: 80px;
      }
    }

    .imageContainer {
      position: relative;
    }

    .deleteIcon {
      position: absolute;
      top: 0;
      right: 0;
    }

    &.loading {
      opacity: 0.5;
      filter: grayscale(80%);
    }
  }

  img {
    object-fit: contain;
    width: 90%;
    height: 200px;
  }
}

.form-upload-section {
  width: 100%;
}

.image-uploaded-item {
  border: 1px solid $primary-color;
  padding: 0px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.add-button {
  align-items: center;
  float: inline-end;
}