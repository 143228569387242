@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.FAQS {
  .addQuestion {
    background-color: #ffa500;
    color: white;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    width: 200px;
    transition: background-color 0.3s ease;
  }

  /* Define the styles for the button on hover */
  .addQuestion:hover {
    background-color: #ff8c00;
    /* Darker orange on hover */
  }

  .exportList {
    .paper-div {
      border-radius: 10px;
      min-height: 300px;
      padding: 20px;
    }
  }
}

.addQuestionsFAQS {
  .flexcontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    align-items: stretch;
    background-color: #fff;
    width: 100%;
    padding: 20px;

    .form-section {
      display: flex;
      flex-direction: column;

      .form-input {
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          font-weight: bold;
          margin-bottom: 5px;
        }

        input[type="text"],
        textarea {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          background-color: #f5f5f5;
          font-size: 14px;
        }

        textarea {
          resize: none;
          height: 80px;
        }
      }

      .switch {
        display: flex;
        align-items: center;
        gap: 10px;

        input[type="checkbox"] {
          margin-right: 10px;
        }

        label {
          font-size: 14px;
        }
      }
    }
  }
}

.hoverRow {
  background-color: #ffa60055 !important
}
