@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";
.navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 3%;
  max-width: 100%;
  height: 56px;
  box-shadow: 0px 3px 7px 0px rgba(75, 164, 240, 0.3);
  align-items: center;
  background-color: white;
  transition: 0.1s all ease-out;
  z-index: 4;
  top: 0;
  position: fixed;

  @media only screen and (max-width: $sm-screen) {
    padding: 0px 1%;
  }

  .logo-container {
    display: flex;
    align-items: center;
    .LogoSVG {
      width: 80px;
      height: fit-content;
    }
  }
  .header-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.logoutPopover {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: $box-shadow;
  border-radius: 10px;
  width: 200px;
  z-index: 100;
  a,
  div {
    display: flex;
    align-items: center;
    gap: 10%;
    padding: 3% 5%;
    &:nth-child(1) {
      border-bottom: 1px solid rgba(168, 168, 168, 1);
    }
  }
}
