.discountDetails {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  .discountDetailsCard {
    border-radius: 10px;
    margin-bottom: 10px !important;

    .action-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
  .discount_model_dynamic {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    column-gap: 1rem;

    .active-checkbox {
      align-items: flex-start;
      margin-top: 25px;
    }

    .choose-model {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
    }
  }

  .models {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
    .formWrapper {
      flex-grow: 1;
    }
  }
  .attSelects {
    margin-left: 2rem;
  }
}
