@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.create-role {
  min-width: 50%;
  min-height: 100%;

  transition: 0.1s all ease-out;
  .permission-container {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-shadow: $box-shadow;
    // background-color: red;
    height: 40vh;
    overflow-y: scroll;
    background-color: #d3d3d32c;
    min-height: 180px;
    &::-webkit-scrollbar-track {
      background: transparent;
      border: none;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(128, 128, 128, 0.37);
      border-radius: 50px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(63, 63, 63, 0.761);
      transform: scale(1.2);
      // border-radius: 20px;
    }
    &:hover {
      overflow-y: scroll;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
  .create-role-header {
    max-width: 1600px;
    margin: 0 auto;
    h1 {
      // margin-top: -30px;
      margin-bottom: 40px !important;
    }
  }
  .create-role-form {
    max-width: 1600px;
    margin: 0 auto;
    width: 100%;
    min-height: 100px;
    background: #ffffff;
    box-shadow: $box-shadow;
    border-radius: 10px;
    // padding: 40px 40px 40px 40px;
    .create-role-Description-container {
      border: 1px solid #a8a8a8;
      min-height: 150px;
      padding: 50px 50px 50px 42px;
      margin-top: 20px;
      box-sizing: border-box;
      border-radius: 8px;
      text-align: justify;
      text-justify: inter-word;
      color: gray;
      font-size: 19px;
    }
    .search-permission {
      display: flex;
      // flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      .form-input {
        height: 55px;
      }
    }
    .create-role-form-footer {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      background-color: red;
    }

    .create-role-form-container {
      width: 80%;
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 920px) {
        flex-direction: column;
      }

      .create-role-form-input-left {
        width: 60%;
        label {
          @include font-size20();
          margin-bottom: 5px;
        }
        @media only screen and (max-width: 1166px) {
          width: 40%;
        }
        @media only screen and (max-width: 920px) {
          width: 100%;
        }
      }
      .create-role-form-input-right {
        width: 40%;
        @media only screen and (max-width: 620px) {
          width: 100%;
        }
        @media only screen and (max-width: 920px) {
          width: 100% !important;
        }
      }
    }

    .form-input {
      width: 70%;
      height: 80px;
      flex-direction: column;
      @media only screen and (max-width: 1166px) {
        width: 100%;
      }
    }
  }
}
