$border-radius: 5px;
$sm-screen: 900px;
$box-shadow: 0px 0px 4px rgba(222, 228, 237, 0.8);
@mixin font-size45() {
  font-size: clamp(25px, 2.343vmax, 45px);
}
@mixin font-size30() {
  font-size: clamp(20px, 2.343vmax, 30px);
}
@mixin font-size() {
  font-size: clamp(16px, 1.45vmax, 28px);
}
@mixin font-size24() {
  font-size: clamp(16px, 1.25vmax, 24px);
}
@mixin font-size22() {
  font-size: clamp(16px, 1.15vmax, 22px);
}
@mixin font-size20() {
  font-size: clamp(16px, 1.05vmax, 20px);
}
@mixin font-size18() {
  font-size: clamp(14px, 0.9375vmax, 18px);
}
@mixin font-size16() {
  font-size: clamp(12px, 0.8375vmax, 16px);
}
@mixin font-size14() {
  font-size: clamp(10px, 0.73vmax, 14px);
}
@mixin font-size12() {
  font-size: clamp(8px, 0.73vmax, 12px);
}
