@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.TableHeader {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0px 0 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  @media screen and (max-width: $sm-screen) {
    position: unset;
  }

  &.normalPosition {
    position: unset;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }

  .checkboxes-spaces {
    height: 200px !important;
    overflow: auto !important;
  }
  .PlusSVG {
    padding: 0;
    min-width: 0;
  }
  .TransferSVG {
    svg {
      height: 37px !important;
    }
    padding: 0;

    min-width: 0;
  }
}
