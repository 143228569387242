@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.side-menu-header {
  padding: 5%;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  box-shadow: 0px 3px 7px 0px rgba(75, 164, 240, 0.3);
  margin-top: -10px;
}
.LogoSVG {
  width: 80px;
}
.tabs {
  width: 100%;
  min-height: 100px;
  margin-top: 20px;
  z-index: 20;
  .tab {
    min-height: unset;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    text-transform: capitalize;
    justify-content: flex-start;
    gap: 15px;
    text-align: left;
    white-space: nowrap;
    font-weight: unset;
    color: #9c9c9c;
    &:hover {
      color: #4ba4f0;
    }
    &.Mui-selected {
      svg path,
      svg rect {
        fill: $primary-color;
      }
      .SetOfQuestionsSVG path {
        fill: white;
        &:first-child {
          fill: $primary-color;
        }
      }

      .VariantsSVG path {
        fill: $primary-color;
        &:first-child {
          fill: white;
        }
      }
    }
    svg {
      width: 20px;
      height: unset;
    }
  }
}
.sidebarContent {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin-top: 10px;
  .dropdown {
    display: flex;
    flex-direction: column;
    a {
      padding: 15px 0 20px 50px;
    }
  }
  .accordionSummary {
    background-color: transparent;
    box-shadow: none !important;
    padding: 20px;
    width: 100%;
    div {
      margin: 0;
      p {
        display: flex;
        column-gap: 0.7rem;
        align-items: center;
      }
    }
  }
  .accordionSummary[aria-expanded="true"] {
    // background-color: #e9f5ff;
    border-bottom: none !important;
    color: #4ba4f0;
    svg path,
    svg,
    svg g rect {
      fill: #4ba4f0 !important;
    }
  }
  .accordionSide,
  .css-15zcubg-MuiPaper-root-MuiAccordion-root {
    box-shadow: none !important;
    border: 0;
    width: 100%;
  }
  .linkSide {
    display: flex;
    align-items: center;
    column-gap: 0.7rem;
    padding: 15px 20px;
    justify-content: flex-start;
    svg,
    svg path,
    // .linkLogo {
    //   fill: #a1adbd;
    //   font-size: 18px;
    // }
    svg {
      width: 20px;
    }
  }
  .linkSide:hover,
  .accordionSummary:hover {
    background-color: #e9f5ff;
    color: #4ba4f0;
    svg path,
    svg g rect {
      fill: #4ba4f0;
    }
  }
  .linkDrop {
    box-shadow: none !important;
    border: 0 !important;
    display: flex;
    justify-content: flex-start;
  }
  .linkDrop:hover {
    background-color: #e9f5ff;
    color: #4ba4f0;
  }
  .linkLogo svg {
    width: 20px !important;
  }
  .accordionDetails {
    padding: 0;
    min-width: 240px;
  }
}
.css-15zcubg-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
// .css-1xevy71.MuiList-root {
//   min-height: 300px;
//   width: 100%;
//   max-height: 80vh;
//   overflow: hidden overlay;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }
// ::-webkit-scrollbar-thumb {
//   display: none !important;
//   background: rgba(2, 191, 204, 0.388);
//   border-radius: 25px;
// }
::-webkit-scrollbar {
  width: 0;
}
