@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.FilterSVG {
  min-width: 0 !important;
  padding: 0 !important ;
}

.filterComponent {
  max-height: 300px !important;
}
