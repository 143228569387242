@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.products-details {
  margin: 10px 0px;

  .paper-div {
    border-radius: 10px;
  }

  .error-message {
    color: #ff0000;
    font-size: 14px;
    margin: 5px 0px;
  }

  .header {
    display: flex;
    align-items: center;
    color: rgb(18, 25, 38);
    padding: 24px;
    border-bottom: 1px solid $primary-color;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: self-start;
      gap: 20px;
    }

    .title-details {
      font-size: 1.125rem !important;
      margin: 0px;
      font-size: 0.875rem;
      color: rgb(18, 25, 38);
      font-weight: 500;
      line-height: 1.334;
      display: block;
    }

    .chip-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: end;
      width: 20%;

      @media only screen and (max-width: 800px) {
        justify-content: start;
        width: 100%;
      }

      .en-chip {
        margin: 0px 5px;
      }

      .color-white {
        color: #fff;
      }
    }
  }

  .body-wrapper {
    display: flex;
    flex-direction: row;
    padding: 24px;
    align-items: start;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }

    .body {
      width: 60%;

      @media only screen and (max-width: 800px) {
        width: 100%;
      }

      .form-body {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: start;

        @media only screen and (max-width: 800px) {
          flex-direction: column;
        }
      }

      .margin-top-5 {
        margin-top: 1%;
      }

      .width-100 {
        width: 100%;
      }
    }

    .upload-body {
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 800px) {
        width: 100%;
      }

      .fileUpload {
        font-weight: 500;
        font-size: 16px;
        color: #4ba4f0;
        display: inline-block;
        padding: 12px 53px !important;
        border: 1px solid #4ba4f0;
        border-radius: 4rem;
        display: flex;
        flex-direction: row;
        column-gap: 15px;
      }

      .fileUpload {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        z-index: 0;
        height: 100%;
        margin-bottom: 0.7rem;
      }

      .fileInput label {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        p {
          color: #b9b3b3;
          font-size: 16px;
        }
      }

      .fileInput label:hover,
      .fileInput input:hover {
        cursor: pointer;
      }

      .fileInput {
        position: relative;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%) !important;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        background-color: #fff;
      }

      .fileInput {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
      }

      .image-fileinput {
        height: 80%;
      }

      .fileList {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 0;
        column-gap: 1rem;
        margin-top: 1rem;
        flex-wrap: wrap;
        row-gap: 1rem;
        margin: 0;
      }

      .fileListElement,
      .fileList li button {
        background-color: #fff;
        padding: 0.5rem;
        width: fit-content;
        color: #7989a3;
      }

      .fileList li {
        display: flex;
        flex-direction: row;
        box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%) !important;
        border-radius: 4px;
        width: fit-content;
      }

      .fileList li button {
        border: none;
      }

      .formInputWrapper {
        margin: 0px 0px 15px 0px;

        .css-apgn7c-MuiInputBase-root-MuiInput-root:before {
          border-bottom: 1px solid #a1adbd !important;
        }

        .MuiAutocomplete-tag {
          background: #eaf1fc;
          border-radius: 5px;
          color: #4ba4f0;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;

          svg path {
            color: #4ba4f0;
          }
        }
      }

      .filesSection {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-top: 10px;
        height: fit-content;

        @media only screen and (max-width: $sm-screen) {
          width: 100%;
        }

        .image {
          position: relative;
        }

        .image img {
          border-radius: 10px;
          box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0) 50.52%,
            rgba(0, 0, 0, 0.2) 100%
          );
          width: 120px;
          height: 120px;
        }

        .image button {
          position: absolute;
          border-radius: 100%;
          background: #8cb8f4;
          width: 24px;
          height: 24px;
          padding: 0;
          color: white;
          left: 105px;
          top: -10px;
        }
      }

      .action-footer {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0px;
      }

      .files {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 1rem;
        column-gap: 1rem;
        margin-top: 1rem;
      }
    }

    .inputs-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .mx-2 {
    margin: 10px 8px !important;
  }

  .mx-8 {
    margin: 0px 8px !important;
  }

  .factors-div {
    padding: 24px;
  }

  .width-25 {
    width: 25%;

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }

  .width-50 {
    width: 50%;

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }

  .width-75 {
    width: 75%;

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }

  .width-100 {
    width: 100%;
  }

  .mt-17 {
    margin-top: 2px !important;
  }

  .mt-6 {
    margin-top: 6px !important;
  }

  .w-90 {
    width: 90%;
  }

  .avatarBg {
    background-color: #4ba3f6bd !important;
  }

  .viewModeImages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
