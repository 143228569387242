.our-partner {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    /* Allow items to wrap on smaller screens */
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    /* Spacing between the cards */

    .greycard-background {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        border-radius: 8px;
        background-color: #E8E8EA !important;

        /* Grey background for inactive cards */
        &:hover {
            transform: scale(1.05);
        }
    }





    .partner-card,

    .addPartner {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        border-radius: 8px;

        &:hover {
            transform: scale(1.05);
        }
    }

    .addPartner {
        background-color: #e6f3ff;
    }

    /* Stack cards vertically on smaller screens */
    @media (max-width: 768px) {
        flex-direction: column;
        /* Stack items vertically */
        align-items: center;
        /* Center items horizontally */

        .partner-card,
        .addPartner {
            width: 100%;
            /* Full width for each card */
            max-width: 300px;
            /* Optional: Limit max width to keep it readable */
            height: auto;
            /* Adjust height automatically */
        }
    }

    @media (max-width: 576px) {

        .partner-card,
        .addPartner {
            width: 100%;
            /* Full width for each card */
            max-width: 280px;
            /* Limit max width further */
            height: auto;
            /* Adjust height automatically */
        }
    }

    @media (max-width: 375px) {

        .partner-card,
        .addPartner {
            width: 100%;
            /* Full width for each card */
            max-width: 250px;
            /* Limit max width further */
            height: auto;
            /* Adjust height automatically */
        }
    }
}