@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.payment-info {
  // min-width: 100%;
  min-height: 100%;

  transition: 0.1s all ease-out;

  .payment-info-header {
    width: 100%;
    min-height: 80px;
    display: flex;
    justify-content: flex-start;
  }
}
