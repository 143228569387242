.wrapper-container {
  justify-content: center;
  display: flex;
  align-items: center;

  margin: 20px;

  .wrapper-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  input {
    display: none;
  }

  .img-container {
    margin-top: 30px;

    img {
      display: block;
    }
  }

  .cropped-image {
    max-width: 400px;
    max-height: 400px;
  }

  .width50 {
    width: 50%;
  }

  .width80 {
    width: 80%;
  }


}

.uploading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  gap: 10px;

}

.iconRotate {
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}



.width27 {
  width: 27% !important;
  margin: 0px !important;

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.displayBlock {
  display: block !important;
}

.height200 {
  height: 200px !important;
}

.margin0 {
  margin: 0px !important;
}