@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.currencyComponent {
  .hidden-link {
    color: black;
    text-decoration: underline !important;
  }

  .hidden-link:hover {
    color: $primary-color !important;
  }
}
