.lawandorder {
  .store-title {
    padding: 20px 0px;
    font-size: 22px;
    font-weight: 500;
  }

  .MuiTab-root {
    text-transform: none;

    &.Mui-selected {
      color: #3538cd;
    }
  }

  .MuiTabs-indicator {
    background-color: #3538cd;
  }
}
