@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.searchComponent {
  display: flex;
  width: clamp(120px, 25vw, 350px);
  height: 40px;
  min-width: 150px;
  border-bottom: solid 1px $primary-color;
  padding: 0 10px;
  background: white;

  input {
    border: none;
    outline: none;
    width: 100%;
    padding: 0 2%;
    background: none;
    @include font-size18;
  }
  .searchIcon {
    width: 30px;
    cursor: pointer;
    align-self: center;
  }
  &.curvedSearchComponent {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    border: none;
    flex-direction: row-reverse;
  }
}
