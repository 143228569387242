@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.organization-handle-component {
  display: flex;
  gap: 2%;

  @media screen and (max-width: $sm-screen) {
    flex-flow: column;
  }

  .organization-handle-form-section {
    display: flex;
    flex-flow: column;

    .action-footer {
      display: flex;
      justify-content: flex-end;
    }

    .form-double-children {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px 40px;
      align-items: start;

      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    }
    .map-height {
      height: 300px;
    }

    .scrollable-ui {
      overflow-y: scroll;
      max-height: 200px;
      margin: 10px;
    }

    .scrollable-ui-country-factor {
      max-height: 300px;
      overflow-y: auto;
      width: 50%;

      &.full-width {
        width: 100% !important;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      .country-factor-dynamic-form {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 1%;

        .remove-section {
          width: 10%;
        }
      }
    }
  }
  .full-view {
    width: 100%;
  }

  .mid-view {
    width: 40%;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
  }
  .organizations-handle-side-section {
    display: flex;
    flex-flow: column;
    gap: 15px;
    width: 60%;
    @media screen and (max-width: $sm-screen) {
      margin: 10px 0px;
      width: 100%;
    }

    .no-padding-top {
      padding-top: 0px !important;
    }

    .header {
      border-bottom: 1px solid #eaedf2;
    }
    .organization-factor-header {
      display: flex;
      flex-flow: column;

      div {
        color: #7989a3;
        @include font-size14();
      }
    }
    .organization-branches-header {
      display: flex;
      flex-flow: column;

      div {
        color: #7989a3;
        @include font-size14();
      }
    }

    .organizations-branches {
      .search-section {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin: 20px 0px;
      }
    }
  }
}
.handle-country-org-factor {
  .action-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
