@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";

.collapse-table-component-container {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: initial;

  tbody .trBody {
    cursor: pointer;
    transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover {
      background-color: $primary-color-opacity;
    }
  }

  .collapse-table-component-table {
    padding: 1px;
    border-collapse: unset;
    border-spacing: 0 10px;

    .collpase-table-component-row {
      box-shadow: $box-shadow;
      background-color: white;
      .header-label {
        color: #041c3f;
        @include font-size18();
        font-weight: 500;
      }
      .collapse-detail {
        width: 100%;
      }
    }

    .collapse-row {
      padding: 0px;
    }
  }
}
