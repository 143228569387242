.product-table-container {

    .redColor {
        color: #ff0000 !important;
        font-weight: bold;
    }

    .spinnerSection {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

    }
}