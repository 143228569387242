@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";


// To switch to arabic langauge
.arabic-component {
    direction: rtl;
    /* Set text direction to right-to-left */
    font-family: "Arial", sans-serif;
    /* Use an Arabic font */
    text-align: right;
}


//Mangage Retailer Component

.home-group-component {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    flex-flow: column;

    justify-content: center;

    .home-header {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        .title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #4ba4f0;
            font-weight: 500;
            @include font-size20();
        }

        .actions {
            display: flex;
            flex-direction: row;
        }
    }

    .home-page-listing {
        min-height: 500px;
        width: 100%;

        .home-page-list-element {
            width: 100%;
            margin-bottom: 10px;

            .home-group-list-card {
                .home-group-content {
                    padding: 5px 10px !important;
                }
            }

            .element-container {
                display: flex;
                height: 50px;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;

                .name {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow-x: hidden;
                    width: 70%;
                }
            }
        }
    }

    .form-handle {
        margin: auto;
        width: 70%;
        max-width: 800px;

        @media screen and (max-width: $sm-screen) {
            width: 100%;
        }

        display: flex;

        .form-section {
            width: 100%;
        }

        .form-second-card {
            margin: 10px 0px;

            .home-page-group-checkboxes {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media screen and (max-width: 600px) {
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }

            .form-double-children {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 10px 40px;
                align-items: center;

                @media screen and (max-width: 600px) {
                    grid-template-columns: 1fr;
                }
            }
        }

        .countries-select-all {
            display: flex;
            flex-flow: row nowrap;
            column-gap: 1rem;
            align-items: center;

            .countries-select {
                width: 70%;
            }
        }

        .action-footer {
            display: flex;
            justify-content: flex-end;
        }
    }


}

.ManageRhandle {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    .action-footer {
        display: flex;
        justify-content: flex-end;
    }

    .iconen {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .iconar {
        position: absolute;
        left: 8px;
        top: 8px;
    }

    .buttonen {
        display: flex;
        justify-content: end;
    }

    .buttonar {
        display: flex;
        justify-content: end;
    }



}

.action-footer {
    display: flex;
    justify-content: flex-end;
}