.map {
  height: 100%;
  @media only screen and (max-width: 600px) {
    margin: auto;
  }
}
.pin {
  color: white;
  background: red;
  padding: 5px;
  width: 25px !important;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transform: translate(-50%, -50%);
}
.pinImage {
  width: 25px;
  margin-left: -12px;
  margin-top: -42px;
}
