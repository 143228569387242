@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";

.CountryDetails {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;

  .CountryDetailsCard {
    width: 50%;
    border-radius: 10px;

    @media only screen and (max-width: $sm-screen) {
      width: 100%;
    }
    .map-height {
      height: 300px;
    }

    .action-footer {
      display: flex;
      margin: 10px 0px;
      justify-content: flex-end;
    }
  }
}
.countryDiv {
  h1 {
    margin-bottom: 20px;
  }
}
