@import "../../theme/color.scss";

.Accordion_account {
  display: initial !important;
  .accordion-button:focus {
    z-index: 3;
    border-color: unset;
    outline: 0;
    box-shadow: unset;
  }
  .accordion-button:not(.collapsed) {
    color: $primary-color;
    background-color: unset;
  }
}
