.banners {
    .store-title {
        padding: 20px 0px;
        font-size: 22px;
        font-weight: 500;
    }
}


.addBanners {
    .store-title {
        padding: 20px 0px;
        font-size: 22px;
        font-weight: 500;
    }

}