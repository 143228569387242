@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.dialog-title-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.title-component {
  margin: 0px 0px 10px 0px;
}
