@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Libre+Barcode+EAN13+Text&display=swap");
.Order_detailsDisclaimer {
  width: 100%;
  margin: 0 auto;
  // padding-top: 2%;
  max-width: 1200px;
  padding-left: 5%;

  min-height: calc(100vh - 70px);
  .barcode {
    line-height: 32px;
    padding: 0;
    padding-right: 20px;
    font-family: "Libre Barcode EAN13 Text", cursive;
    font-size: 50px !important;
    // transform: scaleX(2) translateX(-50px);
    white-space: nowrap;
    max-height: 32.5px;
  }
  h4 {
    white-space: nowrap;
    font-weight: 500;
    color: $primary_color;
  }

  .Items_summary {
    border: 1px solid #eaf1fc;
    border-radius: 10px;
    @media print {
      margin: 50px 0 0;
    }

    .headerAccor {
      @media only screen and (max-width: 900px) {
        display: none;
      }
    }
    .accordions {
      padding: calc(0px + 1%) calc(5px + 2%);
      span {
        padding: 10px 0;
        max-width: 60%;
      }
      .headerAccordion {
        @include font-size18();

        line-height: 28px;
        font-weight: 500;
        padding: 10px 0;
      }
      .is-open .list2 {
        font-weight: 500;
        border-bottom: 1px solid black;
        @media only screen and (max-width: 900px) {
          border-bottom: none;
        }
      }
    }
    .accordion {
      margin-bottom: 2%;
    }
    &::-webkit-scrollbar {
      width: 5px !important;
    }
    .Table_titles {
      padding: 0 3%;
      margin-bottom: 1%;
      display: flex;
      align-items: center;
      height: 50px;
      font-weight: 500;
      gap: 40%;
      box-shadow: $box-shadow;
      white-space: nowrap;
      &:hover {
        box-shadow: 0px 0px 5px 1px $primary-color-opacity;
      }
      p {
        width: 15%;
        margin: 0;
        font-weight: 500;
      }
    }
  }

  .Details_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .Summary {
      width: 45%;
      padding: 5px;
      p {
        margin: 0%;
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include font-size16();
      }

      .Info {
        border-radius: 10px;
        padding: 2% 5%;
        border: 1px solid #eaf1fc;
        .Sub_row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2%;
          min-height: 25px;
          font-size: 17px;
          .Left {
            font-weight: 500;
          }
        }
        .buttonContainer {
          display: flex;
          gap: 5%;
        }
      }
    }
  }
  // svg {
  //   width: 15px;
  //   align-self: center;
  // }

  .newOffer {
    color: green;
    font-size: 10px !important;
  }

  @media only screen and (max-width: 900px) {
    svg {
      display: none;
    }
  }

  .newOffer {
    color: green;
    font-size: 10px !important;
  }

  .estimated_value,
  .shipping {
    font-style: normal;
    font-weight: 500;
    @include font-size20();
    line-height: 28px;
    display: flex;
    align-items: center;
    color: $grey;
    @media only screen and (max-width: 400px) {
      font-size: 14px !important;
    }
  }

  .offered_value {
    @include font-size18();
    line-height: 28px;
    display: flex;
    align-items: center;
    color: $black;
    &.barcode {
      font-family: "Libre Barcode EAN13 Text", cursive;
      font-size: 50px !important;
    }
  }

  .mobile_svg {
    display: none;
    @media only screen and (max-width: 900px) {
      display: flex;
      margin: auto auto 9px;
      width: 14px;
      height: 7px;
    }
  }
}
@media only screen and (max-width: 900px) {
  .accordion_list_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .list2 {
      direction: ltr;
    }
  }
  .Order_details {
    .Items_summary {
      .Table_titles {
        font-size: 15px;
        font-weight: 500;
      }
    }
    .Details_row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 50px;
      break-inside: avoid !important;
      // page-break-before: always !important;
      // break-before: always !important;
      // break-after: always !important;

      .Summary {
        width: 100%;
        break-inside: avoid;

        .Info {
          font-size: 12px;
          break-inside: avoid;
        }
      }
    }
  }
}
.accordion-opened {
  svg {
    transform: rotate(180deg);
  }
}

.hide svg {
  display: none;
}
.tableDisclaimer {
  width: 100%;

  thead {
    width: 100%;
    border-bottom: 2px solid #eaf1fc;
  }
  tbody,
  td,
  tfoot,
  th,
  thead {
    padding: 5px 20px;
  }
  thead {
    .h1 {
      padding: 5px 20px;
      text-align: left !important;
    }
    .h6 {
      padding: 5px 20px;
      text-align: left !important;
      color: $darkest-grey;
    }
  }
  tr {
    td,
    th {
      &:last-child {
        text-align: end;
      }
    }
    th {
      color: $primary-color;
      white-space: nowrap;
    }
  }
}
