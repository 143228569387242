@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";

.create-report {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 600px;

    h1 {
        margin-bottom: 20px;
    }

    .report-form {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;

        .button-group {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
            gap: 10px;
        }
    }
}