@import "/src/theme/variables.scss";
@import "/src/theme/color.scss";
.account_details {
  background: $white;
  border-radius: 10px;

  @media screen and (max-width: 601px) {
    display: none;
  }
  .title {
    font-size: 38px;
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
}

.Responsive {
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media screen and (min-width: 601px) {
    display: none;
  }
}
.accordion {
  border: none !important;
}

.Accordion_account {
  display: flow-root !important;
}
