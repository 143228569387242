@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.paginator-section {
  display: flex;
  width: 100%;

  justify-content: flex-end;

  .paginator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    border: 0.5px solid $primary-color;
    min-width: 40px;
    border-radius: 30px;
    padding: 4px;
  }
}
