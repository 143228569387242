@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.user-notifications {
  .user-notification-elements {
    margin: 15px 0px;
    .grouped-date {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      @include font-size20();
      color: $primary-color;
      font-weight: 500;
    }

    .notify-list {
      box-shadow: $box-shadow;
      margin: 5px 0px;
      border-radius: 10px;

      .notify-item-content {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        .notify-desc {
          width: 70%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;

          @media only screen and (max-width: $sm-screen) {
            flex-flow: column nowrap;
            width: 70%;
            align-items: flex-start;
          }
        }
        .state-hour {
          @include font-size16();
          color: $grey;
        }
      }
    }
  }
}
