@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.notice-confirmation-dialog {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 0px 30px 0px 30px;

  .title {
    color: $primary-color;
    @include font-size20();
    text-align: center;
    margin-bottom: 10px;
  }
  .message {
    @include font-size16();
    text-align: center;
  }
}
