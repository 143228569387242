@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.becomeaPartner {
  .MuiTab-root {
    text-transform: none;
    &.Mui-selected {
      color: #3538cd;
    }
  }
  .MuiTabs-indicator {
    background-color: #3538cd;
    /* Hide the default indicator */
  }
}

.informationSection {
  background-color: #fff;
  padding: 10px 5px;
}
