@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.transferList {
  overflow: scroll;

  .paper-div {
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
  }

  .transfer-product-sku-items {
    display: flex;
    flex-flow: column;
    row-gap: 2%;

    @media screen and (max-width: $sm-screen) {
      flex-flow: column;
    }

    .transfer-left-section {
      width: 100%;
      display: flex;
      flex-flow: column;

      @media screen and (max-width: $sm-screen) {
        width: 100%;
      }

      .transfer-form-section {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;


        .action-footer {
          display: flex;
          margin: 10px 0px;
          justify-content: flex-end;
        }
      }
    }

    .item-selected {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 10px;
    }

    .transfer-item-list {
      width: 50%;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: $sm-screen) {
        margin: 10px 0px;
        width: 100%;
      }
    }

    .already-selected-items {
      width: 50%;

      .list-items-selected {
        max-height: 600px;
        overflow-y: scroll;
      }

      .margin-error {
        margin: 0px 10px;
      }
    }
  }

  .width25 {
    width: 25% !important;
  }
}

.filterby {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.cardtableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 10px;
}

.feesPOPUP {
  display: flex;
  flex-direction: row;

  .formfees {
    width: 100%;
  }

  .feesRow {
    display: flex;
    flex-direction: column;
    width: 100%;
    column-gap: 5px;
    justify-content: center;
    align-items: center;
  }

  .dynamic-fees-add {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
  }

  .buttonFees {
    display: flex;
    flex-direction: row;
  }

  .buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 5px
  }

  .feesIconButton {
    &:hover {
      background-color: none !important;
    }
  }
}