@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.about {
  .MuiTab-root {
    text-transform: none;

    &.Mui-selected {
      color: #3538cd;
    }
  }

  .MuiTabs-indicator {
    background-color: #3538cd;
    /* Hide the default indicator */
  }
}

.aboutusSection {
  background-color: #fff;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;

  .viewModeHeader {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin: 10px 0px;
  }

  .viewMode {
    margin-top: 10px;

  }
}

.bannerSection {
  background-color: #fff;
  padding: 10px 5px;
  margin: 10px 0px;
}

.displaynone {
  display: block;
  @media screen and (max-width: 400px) {
    display: none;
  }
}