@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.users {
  min-height: 100%;
  transition: 0.1s all ease-out;
}

.user-handle-component {
  display: flex;

  gap: 2%;
  @media screen and (max-width: $sm-screen) {
    flex-flow: column;
  }

  .user-handle-form-section {
    width: 40%;
    display: flex;
    flex-flow: column;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }

    .action-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
  .user-handle-activity-section {
    width: 60%;
    .activity-card {
      height: 100%;
    }
  }
}
