@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.manage-rules-component {
  .condition-link {
    color: black;
    text-decoration: underline !important;
  }

  .condition-link:hover {
    color: $primary-color !important;
  }
}

.conditions-factor-handle {
  .form-handle {
    display: flex;
    flex-flow: row nowrap;
    gap: 10%;
    align-items: center;

    .label {
      width: 50%;
      text-align: start;
    }
  }
}
.manage-attribute-handle-component {
  .list-item-attribute {
    cursor: pointer;
  }

  .attribute-factor-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .back-button {
      padding-left: unset;
    }
  }
  .attribute-factor-handle {
    .form-handle {
      display: flex;
      flex-flow: row nowrap;
      gap: 10%;
      align-items: center;

      .label {
        width: 50%;
        text-align: start;
      }
    }
  }
}
