@import "/src/theme/color.scss";

.head {
  color: $primary-color;
  text-align: center;
  font-weight: bold;
}

.form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  padding-left: 0px;
  &:focus {
    color: $black;
    background-color: $white;
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
  }
  &::placeholder {
    margin-left: none !important;
    padding: 0px;
  }
}

.form-check-input:checked {
  background-color: $primary-color;
  border-color: $primary-color;
}

.Password {
  display: flex;
  justify-content: flex-end;
  color: $grey;
  text-decoration: none;
  margin-bottom: 5px;
  &:hover {
    color: $primary-color;
  }
}

.OrLogin {
  color: $grey;
  display: flex;
  justify-content: center;
  text-decoration: none;
  &:before {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid $border-grey;
    margin: auto;
    margin-right: 10px;
  }
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid $border-grey;
    margin: auto;
    margin-left: 10px;
  }
  &:hover {
    color: $grey;
  }
}

.icon {
  border: 1px solid #0000001f;
  border-radius: 50%;
  padding: 1%;
  margin: 5px;
}

.input-group-text {
  background-color: #fff;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}

.form-select {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  padding-left: 0px;
}

.form-control.is-invalid {
  background-image: none !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.card-body {
  margin: 0px 5px;
}
.form-error-message {
  color: red;
  margin: 10px 0px;
}
.form-inputs-double-children {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 40px;
  align-items: center;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
