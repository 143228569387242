@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.exceptionList {
    .paper-div {

        border-radius: 10px;
        min-height: 300px;
        padding: 20px;

    }

    .reportType {
        display: inline-block;
        padding: 5px 10px;
        background-color: #d0f0f0;
        /* Adjust this to match your background color */
        color: #009688;
        /* Adjust this to match your text color */
        border-radius: 4px;
        text-align: center;
        font-size: 16px;
        /* Adjust font size as needed */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        max-height: 30px;
        /* Limit the bottom height */
        overflow: hidden;
        /* Ensure content does not overflow */
    }

    .organizationReport {
        background-color: #d4edda;
        /* Green background for Organization Report */
    }

    .branchReport {
        background-color: #d1c4e9;
        /* Purple background for Branch Report */
    }

    .userReport {
        background-color: #cce5ff;
        /* Blue background for User Report */
    }

    .transactionReport {
        background-color: #FFA756;
        /* Default color for other types */
    }

    .itemReport {
        background-color: #BA29FF;
        /* Default color for other types */
    }
}


.exception-details {
    margin: 10px 0;

    .paper-div {
        border-radius: 10px;
        padding: 16px;
        max-width: 100%;
    }

    .header {
        display: flex;
        align-items: center;
        padding: 24px;
        border-bottom: 1px solid #ddd;
        flex-direction: row;
        justify-content: space-between;

        @media only screen and (max-width: 800px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }

        .title-details {
            font-size: 1.125rem !important;
            color: rgb(18, 25, 38);
            font-weight: 500;
            line-height: 1.334;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media only screen and (max-width: 600px) {
                font-size: 1rem !important;
            }
        }
    }

    .avatarBg {
        background-color: #4ba3f6bd !important;
    }

    .MuiTypography-body2 {
        white-space: pre-wrap;
        /* Ensures that line breaks in the text are preserved */
        word-wrap: break-word;
        /* Ensures that long words are broken down to fit within the container */
    }
}