.viewNoteCard {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #041c3f;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #646464;
  }
  span {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
}
