@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.branches-handle-component {
  display: flex;
  gap: 2%;

  @media only screen and (max-width: $sm-screen) {
    flex-flow: column;
  }

  .branches-handle-form-section {
    width: 50%;
    display: flex;
    flex-flow: column;

    @media only screen and (max-width: $sm-screen) {
      width: 100%;
      margin-bottom: 10px;
    }

    .action-footer {
      display: flex;
      margin: 10px 0px;
      justify-content: flex-end;
    }
    .map-height {
      height: 300px;
    }
  }
  .branches-handle-side-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media only screen and (max-width: $sm-screen) {
      width: 100%;
    }

    .header {
      border-bottom: 1px solid #eaedf2;
    }
    .no-padding-top {
      padding-top: 0px !important;
    }

    .branches-users {
      .search-section {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin: 20px 0px;
      }
    }
  }
}
