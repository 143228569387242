.attributeItem {
  border-radius: 10px;
  padding: 20px 18px 20px 40px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #041c3f;
    margin: 0;
  }

  div {
    margin: 0;
  }

  div {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
  }

  span {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
}

.attributeHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 18px;
  margin-top: 28px;

  p {
    color: #4ba4f0;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }

  div {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
  }

  div p {
    cursor: pointer;
  }
}
.Uploaded-image {
  max-width: 400px;
  max-height: 200px;
  object-fit: contain;
  margin: 20px;
}

.containerImages {
  position: relative;
  display: inline-block;
}

.image-wrapper {
  position: relative;
}

.buttons {
  position: absolute;
  top: 50%; /* Adjust vertical position as needed */
  left: 30%;
  visibility: hidden; /* Initially hide the buttons */
}

.containerImages:hover .buttons {
  visibility: visible; /* Show buttons on container hover */
}

.ImageDropzone {
  margin: 0px 20px;
  width: 100%;
}
