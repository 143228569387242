@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.userGroups {
  width: 100%;
  display: flex;
  gap: 2%;
  @media screen and (max-width: $sm-screen) {
    flex-flow: column;
  }

  .filters-section {
    width: 40%;
    display: flex;
    flex-flow: column;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }

    .form-section {
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }
  .already-selected-users {
    .list-users-selected {
      max-height: 200px;
      overflow-y: scroll;

      .user-render {
        display: flex;
        flex-flow: row;
      }
    }
    .margin-error {
      margin: 0px 10px;
    }
    .action-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
  .all-users-list {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .search-section {
      margin-bottom: 10px;
    }
    @media screen and (max-width: $sm-screen) {
      margin: 10px 0px;
      width: 100%;
    }
  }
}
