@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.footer {
    .MuiTab-root {
        text-transform: none;

        &.Mui-selected {
            color: #3538cd;
        }
    }

    .MuiTabs-indicator {
        background-color: #3538cd;
        /* Hide the default indicator */
    }

}


.contactinfoSection {
    background-color: #fff;
    padding: 10px 5px;
    margin-top: 10px;


    .action-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 30px !important; // Add margin-top here

        button {
            padding: 10px 20px;
            height: 48px;
            border-radius: 4px;
            margin-top: 80px !important;
            cursor: pointer;
            font-family: inherit;

            &.cancel-button {
                background-color: #ff5a5f;
                color: white;
            }

            &.save-button {
                background-color: #007bff;
                color: white;
            }
        }
    }


}



.paymentmethodSection {
    background-color: #fff;
    padding: 10px 5px;
    margin-top: 10px;


    .action-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 30px !important; // Add margin-top here

        button {
            padding: 10px 20px;
            height: 48px;
            border-radius: 4px;
            margin-top: 80px !important;
            cursor: pointer;
            font-family: inherit;

            &.cancel-button {
                background-color: #ff5a5f;
                color: white;
            }

            &.save-button {
                background-color: #007bff;
                color: white;
            }
        }
    }


}

.footerquickLink {
    background-color: #fff;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;

    .viewModeHeader {
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin: 10px 0px;
    }

    .viewMode {
        margin-top: 10px;

    }
}