.addQForm {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  .addCard {
    padding: 1.5rem;
    height: fit-content;
    border-radius: 10px;
  }

  .add {
    background: #edf6fe;
    border-radius: 10px;
    padding: 20px;
    color: #4ba4f0;
    cursor: pointer;
    display: flex;
    column-gap: 0.5rem;
    align-items: center;

    p {
      margin: 0;
      align-self: center;
    }
  }

  .action-footer {
    display: flex;
    justify-content: flex-end;
  }
}
