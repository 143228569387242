@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.blogs {
  padding: 10px 5px;
  .MuiTab-root {
    text-transform: none;

    &.Mui-selected {
      color: #3538cd;
    }
  }

  .MuiTabs-indicator {
    background-color: #3538cd;
    /* Hide the default indicator */
  }
}

////  Add Blogs /////////////////////////////////////////////////////////////////////////

.addBlogs {
  .addblogSwitch {
    display: flex;
    justify-content: end;
    color: #ffff;
  }

  .flexcontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    align-items: stretch;
    background-color: #fff;
    width: 100%;
    padding: 20px;

    .image-section {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: end;
        gap: 10px;
        margin-top: 20px;

        .cancel-button {
          background-color: #ff5a5f;
          color: white;
          padding: 10px 20px;
          width: 120px;
          height: 48px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }

        .add-button {
          background-color: #007bff;
          color: white;
          padding: 10px 20px;
          border: none;
          width: 171px;
          height: 48px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;

      .form-section,
      .image-section {
        flex: 1;
      }

      .action-buttons {
        margin-top: 0;
      }
    }

    .width100 {
      width: 100% !important;
    }

    .height100 {
      height: 253px !important;
    }

    .width100 {
      width: 100% !important;
    }

    .error {
      color: #ff0000;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .wrapContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .width-container30 {
    width: 29%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .width-container70 {
    width: 69%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.blogsSection {
  background-color: #fff;
  padding: 10px 5px;

  .store-title {
    padding: 20px 0px;
    font-size: 22px;
    font-weight: 500;
  }
}

.adSection {
  background-color: #fff;
  padding: 10px 5px;

}

.blogsSettings {
  padding: 10px 5px;
  margin: 10px 0px;
}