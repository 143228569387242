.notification {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  .notificationCard {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .form-input-wrapper,
  .formInputWrapper {
    margin-bottom: 0;
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #041c3f;
      margin-bottom: 8px;
    }
    .MuiAutocomplete-tag {
      background: #eaf1fc;
      border-radius: 5px;
      color: #4ba4f0;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      svg path {
        color: #4ba4f0;
      }
    }
  }
  .action-footer {
    display: flex;
    margin: 10px 0px;
    justify-content: flex-end;
  }
}
