.wrapper {
  display: flex;
  margin-top: 150px;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}
.wrapper p {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #041c3f;
}
