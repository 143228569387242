@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";
.Invoice {
  width: calc(100vw - 50px);
  min-height: calc(100vh - 50px);
  position: relative;
  overflow: visible;
  padding-left: 25px;
  @media screen {
    width: 0;
    height: 0;
    overflow: hidden;
    min-height: 0;
  }
  table {
    width: 100%;
  }
  .watermark {
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -25px;
    @media screen {
      height: 0;
      overflow: hidden;
      min-height: 0;
    }
  }
  main {
    display: flex;
    flex-direction: column;
    .invoice-details {
      margin-bottom: 50px;
      span {
        width: 35%;
        display: flex;
        justify-content: space-between;
        p {
          color: gray;
        }
      }
    }
    .invoice-addresses {
      width: 100%;
      display: flex;
      gap: 30%;
      margin-bottom: 30px;
      span {
        white-space: nowrap;
      }
    }
    .invoice-table {
      div {
        display: flex;
        gap: 30px;
        b,
        p {
          b {
            color: $primary-color;
          }
          align-self: center;
          flex: 1 1 25%;
          &:first-child {
            flex: 1 1 55%;
          }
        }
        p {
          color: darkslategray;
        }
      }
      hr {
        color: #bad0d0;
      }
    }
    .total {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      width: 35%;
      min-width: 120px;
      span {
        display: flex;
        justify-content: space-between;
        b {
          width: 70%;
        }
        p {
          white-space: nowrap;
        }
      }
    }
  }

  header,
  .header-space,
  footer,
  .footer-space {
    height: 50px;
  }

  header {
    position: fixed;
    top: 0;
    width: calc(100% - 25px);
    display: flex;
    justify-content: space-between;
    @media screen {
      height: 0;
      overflow: hidden;
      min-height: 0;
    }
  }
  footer {
    width: 100%;
    position: fixed;
    bottom: -25px;
    text-align: center;
    margin: auto;
    @media screen {
      height: 0;
      overflow: hidden;
      min-height: 0;
    }
  }
}
