@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.table-component-container {
  min-height: 500px;

  position: relative;

  tbody .trBody {
    cursor: pointer;
    transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover {
      background-color: $primary-color-opacity;
    }
  }

  .table-component-table {
    padding: 1px;
  }
  .external-table {
    border-collapse: unset;
    border-spacing: 0 10px;
  }
}

.table-component-body-row {
  padding: 0;
  background-color: white;
  box-shadow: $box-shadow;

  &.detailed-row {
    cursor: pointer;
  }
  .body-action-cell {
    align-items: center;
    display: flex;
    justify-content: flex-end !important;
    flex-direction: row;
    margin-left: auto;
  }
}
.table-component-header-row {
  .internal-header-cell {
    border-bottom: 1px solid $primary-color;
    padding-top: 0px;
  }
  .internal-action-header-cell {
    border-bottom: 1px solid $primary-color;
    padding-top: 0px;
    width: 100px;
  }
  .external-action-header-cell {
    width: 100px;
  }
  .MuiTableCell-head {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #4ba4f0;
  }
}

.textAlighRight {
  text-align: right !important;
}