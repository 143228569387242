@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.cp-handle-component {
  display: flex;

  gap: 2%;
  @media screen and (max-width: $sm-screen) {
    flex-flow: column;
  }

  .cp-handle-form-section {
    width: 40%;
    display: flex;
    flex-flow: column;

    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }

    .action-footer {
      display: flex;
      margin: 10px 0px;
      justify-content: flex-end;
    }

    .map-height {
      height: 300px;
    }
  }
  .cp-handle-side-section {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media screen and (max-width: $sm-screen) {
      margin: 10px 0px;
      width: 100%;
    }

    .cp-open-hours {
      .body-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 10px;
        .week-day {
          margin: 0;
          width: 10%;
          text-align: left;
        }
        .switch,
        .timePicker {
          display: flex;
          align-items: center;
          gap: 15px;
          width: 20%;
        }
        .timePicker {
          width: 50%;
        }
      }
    }
    .cp-users {
      .search-section {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin: 20px 0px;
      }
    }
  }
  .cp-users-header {
    display: flex;
    flex-flow: column;

    div {
      color: #7989a3;
      @include font-size14();
    }
  }
}
