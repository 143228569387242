@import "/src/theme/color.scss";
@import "/src/theme/variables.scss";

.newProductSku {
  overflow: scroll;

  .imgwidth200 {
    height: 200px;
  }

  .item-detail-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
    margin: 10px 0px;
  }

  .header-detail {
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgb(222 228 237 / 80%) !important;
    width: 100%;
    background-color: #ffff;
    min-height: 50px;
    margin-bottom: 10px;
    justify-content: space-between;

    @media only screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
      gap: 20px;

    }

    .title-details {
      font-size: 1.125rem !important;
      margin: 0px;
      font-size: 0.875rem;
      color: rgb(18, 25, 38);
      font-weight: 500;
      line-height: 1.334;
      display: block;
    }

    .chip-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: end;
      width: 20%;

      @media only screen and (max-width: 800px) {
        justify-content: start;
        width: 100%;
      }

      .en-chip {
        margin: 0px 5px;
      }

      .color-white {
        color: #fff;
      }
    }
  }

  .item-detail {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    width: 70%;
    background-color: #ffff;
    box-shadow: 0px 0px 4px rgb(222 228 237 / 80%) !important;
    min-height: 200px;
  }

  .info-container {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .left-info,
  .right-info {
    width: 45%;
    position: relative;
  }

  .info-row {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info-row:nth-child(2) {
    margin-bottom: 20px;
    /* Add extra space after the second row */
  }

  .label {
    font-weight: bold;
    margin-right: 10px;
    width: 50%;
    text-align: left;
  }

  .value {
    font-weight: normal;
    width: 50%;
    text-align: right;
  }

  /* Style for the vertical line */
  .vertical-line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    height: 60px;
    width: 1px;
    background-color: #02c0cc;
    z-index: 1;
  }

  .form-section {
    margin-bottom: 20px;
  }

  .form-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }

  .form-row>* {
    flex: 1;
  }

  .form-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .title-h5 {
    color: #4ba4f0;
  }

  .itemFlexRow {
    display: flex;
    flex-direction: row;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    .flex60COL {
      display: flex;
      flex-direction: column;
      width: 50%;

      @media (max-width: 800px) {
        width: 100%;
      }
    }

    .flex40COL {
      display: flex;
      flex-direction: column;
      width: 40%;

      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }

  .upload-body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .fileUpload {
      font-weight: 500;
      font-size: 16px;
      color: #4ba4f0;
      display: inline-block;
      padding: 12px 53px !important;
      border: 1px solid #4ba4f0;
      border-radius: 4rem;
      display: flex;
      flex-direction: row;
      column-gap: 15px;
    }

    .fileUpload {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 0;
      height: 100%;
      margin-bottom: 0.7rem;
    }

    .fileInput label {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      p {
        color: #b9b3b3;
        font-size: 16px;
      }
    }

    .fileInput label:hover,
    .fileInput input:hover {
      cursor: pointer;
    }

    .fileInput {
      position: relative;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%) !important;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      background-color: #fff;
    }

    .fileInput {
      width: 100%;
      height: 250px;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
    }

    .image-fileinput {
      height: 80%;
    }

    .fileList {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding: 0;
      column-gap: 1rem;
      margin-top: 1rem;
      flex-wrap: wrap;
      row-gap: 1rem;
      margin: 0;
    }

    .fileListElement,
    .fileList li button {
      background-color: #fff;
      padding: 0.5rem;
      width: fit-content;
      color: #7989a3;
    }

    .fileList li {
      display: flex;
      flex-direction: row;
      box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%) !important;
      border-radius: 4px;
      width: fit-content;
    }

    .fileList li button {
      border: none;
    }

    .formInputWrapper {
      margin: 0px 0px 15px 0px;

      .css-apgn7c-MuiInputBase-root-MuiInput-root:before {
        border-bottom: 1px solid #a1adbd !important;
      }

      .MuiAutocomplete-tag {
        background: #eaf1fc;
        border-radius: 5px;
        color: #4ba4f0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        svg path {
          color: #4ba4f0;
        }
      }
    }

    .filesSection {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin-top: 10px;
      height: fit-content;

      @media only screen and (max-width: $sm-screen) {
        width: 100%;
      }

      .image {
        position: relative;
      }

      .image img {
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
        background: linear-gradient(180deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0) 50.52%,
            rgba(0, 0, 0, 0.2) 100%);
        width: 120px;
        height: 120px;
      }

      .image button {
        position: absolute;
        border-radius: 100%;
        background: #8cb8f4;
        width: 24px;
        height: 24px;
        padding: 0;
        color: white;
        left: 105px;
        top: -10px;
      }
    }

    .action-footer {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0px;
    }

    .files {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 1rem;
      column-gap: 1rem;
      margin-top: 1rem;
    }
  }

  .newEditor {
    width: 66%;
  }

  .action-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
  }

  .save-button {
    background-color: #1e90ff !important;
    color: #ffff !important;
  }

  .save-button:hover {
    background-color: #1c86ee;
    /* Darker blue on hover */
  }

  .newSKUDetails {
    margin-top: 10px;
  }

  .newSKUDetails.newcardSku {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px;
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
    .item-detail-container {
      flex-direction: column;
    }

    .item-detail {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px;
    }

    .upload-image {
      width: 100%;
    }

    .info-container {
      flex-direction: column;
    }

    .left-info,
    .right-info {
      width: 100%;
    }

    .vertical-line {
      display: none;
    }

    .upload-blue-box {
      width: 100%;
      height: 100px;
      /* Reduce height */
    }

    /* Stacking sections vertically */
    .form-section {
      width: 100%;
      margin-bottom: 20px;
    }

    .form-row {
      flex-direction: column;
      gap: 0;
    }

    .upload-image1-container {
      width: 100%;
      margin-left: 0;
    }

    .upload-image1 {
      width: 100%;
      height: 100px;
      /* Reduce height */
    }

    .formInput1,
    .dropdownlist1 {
      width: 100% !important;
    }
  }

  .inspections-summary {
    display: flex;
    flex-flow: column;
    width: 100%;

    .insp-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }

    .inspections-actions {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      margin: 20px 0px;
      width: 100%;
      gap: 2%;
    }
  }

  .inspections-list {
    .inspection-accordion {
      margin-bottom: 5px;

      .inspection-header {
        display: flex;
        flex-flow: column;

        div {
          color: $grey;
          font-weight: 500;
          @include font-size14();
        }
      }
    }

    .inspection-question-element {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .inspection-element {
        width: 50%;

        &.only-answer {
          text-align: right;
        }
      }
    }
  }
}